@import './theme-colors';

.form-label {
  font-weight: 400;
  font-size: 16px;
  color: $BlueGray-800;
  margin-bottom: 8px;
}

.form-control {
  padding: 12px;
  height: 48px;
  border: 1px solid $BlueGray-300;
  border-radius: 4px;
  margin-bottom: 15px;

  &::placeholder {
    font-size: 16px;
    color: $BlueGray-500;
  }
}

.primary-button {
  padding: 12px;
  background-color: $Green-600;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;

  &:disabled {
    cursor: not-allowed;
    background-color: lighten($Green-600, 20%);
  }

  span {
    font-weight: 400;
    line-height: 24px;
    font-size: 16px;
  }
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: black !important;
  background: black !important;
}
