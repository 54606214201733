$primary: #16a34a;
$accent: #1e293b;
$warn: #ef4444;

$Green-600: $primary;
$Green-500: $primary;

$BlueGray-900: #1c1d20;
$BlueGray-800: $accent;
$BlueGray-500: #64748b;
$BlueGray-300: #cbd5e1;
$BlueGray-200: #e2e8f0;
$BlueGray-100: #f1f5f9;
